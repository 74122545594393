import React, { useState, useEffect } from "react";
import axios from "axios";
import Search from '../../styles/search.svg';
import DownArrow from '../../styles/down-arrow.svg';
import { Mixpanel } from '../../Mixpanel';

const WeddingLocationNewQuiz = ({ quizState, setValue, toggleBoolean, handleChangeText, setShowModal }) => {

	document.title="Pick your location | WedMatch"
		const [ locations, setLocations ] = useState([])
		const [ showLocations, setShowLocations ] = useState(false)
    const [ venueResults, setVenueResults ] = useState([])
    const [ showVenueResults, setShowVenueResults ] = useState(false)

    const searchVenues = (e) => {
        if(e.target.value?.length > 1){
            axios.get('/api/get-vendors/14/' + e.target.value)
            .then(r => {
                setVenueResults(r.data.vendors)
            })
        } else{
            setVenueResults([])
        }
    }


		useEffect(() => {
			Mixpanel.track('View Location Page');
			axios.get('/api/locations')
					.then(r => {
							setLocations(r.data)
					})
					// var referral = props.location.search.replace("?","")
					// if(referral.indexOf("&") != -1){
					// 		referral = referral.substring(0,referral.indexOf("&"))
					// }

					localStorage.clear();
			window.pintrk('track', 'lead', {
				lead_type: 'location'
			})
			// axios.post('/api/submit-referral', {code: referral})
		}, [])



	return (
		<div>
			<div>
				<div>
					<div className="header">Wedding Location</div>
					<div className="subheader">
					We'll find vendors near your venue.
					</div>
				</div>


				<div>
					<div className="">Which city is closest to your wedding venue?hi</div>
					{locations.length > 0 &&
						<div onClick={() => setShowLocations(!showLocations)} className="location-dropdown">
							<div style={{display: 'flex', justifyContent: 'space-between'}} >{!quizState.weddingLocation && <span className="dropdown-placeholder">Your venue location</span>}{quizState.weddingLocation && <span className="dropdown-chosen">{locations.find(l => l.id == quizState.weddingLocation).name}</span>} <img style={{width: '16px'}} src={DownArrow} /> </div>
							{showLocations && <div className="location-options">{locations.map(l => <div className="location-option" onClick={() => {setValue('weddingLocation', l.id); setShowLocations(!showLocations)}}>{l.name}</div>)}</div>}
						</div>
					}
					</div>

					{/* {quizState.weddingLocation &&
						<div style={{marginTop: '50px'}}>

							<div className="question">Which venue have you booked? </div>

							{showVenueResults && venueResults?.length > 0 &&
												<div className='overlay transparent' onClick={() => setShowVenueResults(false)}></div>
										}
							<div style={{position: 'relative', zIndex: '6'}}>
								<input name='venueName' className="question-input searchbox-input" placeholder="Search your venue" type="text" value={quizState.venueName} onChange={(e) => { handleChangeText(e); setValue('venueVendorId', null); searchVenues(e); setShowVenueResults(true); }}/>
								<img className='search-icon' src={Search} />
								{showVenueResults && venueResults?.length > 0 &&
									<div className='search-results'>
										{venueResults.map(v => <div onClick={() => {
											setShowVenueResults(false);
											setValue('venueName', v.business_name);
											setValue('venueVendorId', v.id);
										}} key={v.id}>{v.business_name}</div>)}
									</div>
								}
							</div>
							<div style={{marginBottom: '50px', display: 'flex', flexWrap: 'wrap'}}>
								<div>
									<label class="checkbox-container" style={{marginRight: '8px', marginTop: '0px'}}>
										<input
											name='noVenue'
											checked={quizState.venueName=='' && quizState.noVenue && quizState.venueVendorId == null}
											type="checkbox"
											onClick={(e) => {setValue('venueVendorId', null); setValue('venueName', ''); toggleBoolean(e)}}
										/>
										<span class="checkmark email-checkmark" />
									</label>
									<span className="checkbox-label" style={{marginLeft: '0px', marginRight: '24px'}}>No venue yet</span>
								</div>
								<div>
									<label class="checkbox-container" style={{marginRight: '0px', marginTop: '0px'}}>
										<input
											name='privateVenue'
											checked={quizState.venueName=='' && quizState.privateVenue && quizState.venueVendorId == null}
											type="checkbox"
											onClick={(e) => {setValue('venueVendorId', null); setValue('venueName', ''); toggleBoolean(e)}}
										/>
										<span class="checkmark email-checkmark" />
									</label>
									<span className="checkbox-label" style={{marginLeft: '8px'}}>Venue is a private residence</span>
								</div>
							</div>
						</div>
					} */}
					{/* <div style={{textAlign: 'center'}}><button disabled={!quizState.noVenue && !quizState.venueName} className='new-quiz-done-button' onClick={() => setShowModal(null)}>Done</button></div> */}
			</div>
		</div>
	);
};

export default WeddingLocationNewQuiz;
