import React, { useState, useEffect, useContext, useRef } from "react";
import { AnswersContext } from "../../AnswersContext";
import NavbarNew from "./navbarnew.component";
import Timeline from "./timeline.component";
import FooterNew from "./footernew.component";
import { containerVarients } from "../../animations/animations";
import { Mixpanel } from '../../Mixpanel';
import DownArrow from '../../styles/down-arrow.svg';
import ScrollJump from "../scroll-jump.component";

const DateNew = () => {
	const {
		quizState,
		setValue,
		toggleBoolean,

	} = useContext(AnswersContext);
	const [ showMonths, setShowMonths ] = useState(false)
	const [ showDays, setShowDays ] = useState(false)
	const [ showYears, setShowYears ] = useState(false)
	const FooterRender = () => {
		
		if ((quizState.day && quizState.month && quizState.year) || quizState.dateNotDecided) {
			return <FooterNew to="/howimportant" button={true} from="/" buttonText="Next: Priorities" otherText="Basic Information 2/4" stopAnimating="true" />;
		}else {
			return <FooterNew from="/" to="#" unstyled={true} buttonText="Next: Priorities" otherText="Basic Information 2/4" inactive={true} button={false} stopAnimating={true} />;
		}
	};

	function trackNoDate(){
		Mixpanel.track('Date Not Decided Clicked');
	}

	useEffect(() => {
		Mixpanel.track('View Date Page');
		if(quizState.month || quizState.day || quizState.year){
			setValue('dateNotDecided', false)
		}
	}, [quizState.month, quizState.day, quizState.year])

	useEffect(() => {
		if(quizState.dateNotDecided){
			setValue('day', "")
			setValue('year', "")
			setValue('month', "")
		}
	}, [quizState.dateNotDecided])
	
	const months = [
		{name: "January", number: "01", days: 31},
		{name: "February", number: "02", days: 29},
		{name: "March", number: "03", days: 31},
		{name: "April", number: "04", days: 30},
		{name: "May", number: "05", days: 31},
		{name: "June", number: "06", days: 30},
		{name: "July", number: "07", days: 31},
		{name: "August", number: "08", days: 31},
		{name: "September", number: "09", days: 30},
		{name: "October", number: "10", days: 31},
		{name: "November", number: "11", days: 30},
		{name: "December", number: "12", days: 31},
	]

	return (
		<div className="question-page">
			<NavbarNew />
			<Timeline phase="date" />
			<div className="question-container"
				variants={containerVarients}
				initial="hidden"
				animate="visible"
			>
				<div>
					<div className="header">Date</div>
					<div className="subheader">
					We'll only match you with photographers available on your date.
					</div>
				</div>

				<div>
					<div className="question">When is the big day?</div>
					<div className="date-dropdowns">
						<div>
						<div style={{zIndex: '500'}} onClick={() => setShowMonths(!showMonths)} className="location-dropdown date-dropdown">
							<div style={{display: 'flex', justifyContent: 'space-between'}} >
								{(showMonths || !quizState.month) &&
								 	<span className="dropdown-placeholder">Month</span>
								}
								{quizState.month && !showMonths &&
									<span className="dropdown-chosen date-chosen">{months.find(m => m.number == quizState.month).name}</span>
								} 
								<img alt="" style={{width: '16px'}} src={DownArrow} /> </div>
							{showMonths && 
								<div className="location-options date-options">
									{months.map(m => <div className="location-option" onClick={() => {setValue('month', m.number); setShowMonths(!showMonths)}}>{m.name}</div>)}
								</div>
							}
						</div>
						</div>
						<div>
						<div onClick={() => setShowDays(!showDays)} className="location-dropdown date-dropdown">
							<div style={{display: 'flex', justifyContent: 'space-between'}} >
								{(showDays || !quizState.day) &&
								 	<span className="dropdown-placeholder">Day</span>
								}
								{quizState.day && !showDays &&
									<span className="dropdown-chosen date-chosen">{quizState.day}</span>
								} 
								<img alt="" style={{width: '16px'}} src={DownArrow} /> </div>
							{showDays && 
								<div className="location-options date-options">
									{Array.from(Array(28)).map((a, index) =>
										<div className="location-option" onClick={() => {setValue('day', index + 1); setShowDays(!showDays)}}>{index + 1}</div>
									)}
									{(quizState.month !== "02" || quizState.year%4 == 0) &&
									<div className="location-option" onClick={() => {setValue('day', 29); setShowDays(!showDays)}}>29</div>
									}				
									{quizState.month !== "02" &&					
									<div className="location-option" onClick={() => {setValue('day', 30); setShowDays(!showDays)}}>30</div>
									}
									{months?.find(m => m.number == quizState.month)?.days === 31 &&
									<div className="location-option" onClick={() => {setValue('day', 31); setShowDays(!showDays)}}>31</div>
									}
								</div>
							}
						</div>						
						</div>
						<div>
						
						<div onClick={() => setShowYears(!showYears)} className="location-dropdown date-dropdown">
							<div style={{display: 'flex', justifyContent: 'space-between'}} >
								{(showYears || !quizState.year) &&
								 	<span className="dropdown-placeholder">Year</span>
								}
								{quizState.year && !showYears && 
									<span className="dropdown-chosen date-chosen">{quizState.year}</span>
								} 
								<img alt="" style={{width: '16px'}} src={DownArrow} /> </div>
							{showYears && 
								<div className="location-options date-options">
									<div className="location-option" onClick={() => {setValue('year', 2023); setShowYears(!showYears)}}>2023</div>
									<div className="location-option" onClick={() => {setValue('year', 2024); setShowYears(!showYears)}}>2024</div>
									<div className="location-option" onClick={() => {setValue('year', 2025); setShowYears(!showYears)}}>2025</div>
								</div>
							}
						</div>
					</div>
					</div>
				</div>
				<label class="checkbox-container">
					<input 
						checked={quizState.dateNotDecided}
						type="checkbox"
						name='dateNotDecided'
						onClick={(e) => {trackNoDate(); toggleBoolean(e)}}										
					/>
					<span class="checkmark email-checkmark" />
				</label>
				<span className="checkbox-label">We haven't decided yet</span>
				<ScrollJump />
			</div>
			<FooterRender />
		</div>
	);
};

export default DateNew;
