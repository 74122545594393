import React, {useState, useEffect} from 'react';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { differenceInCalendarDays } from 'date-fns';
import axios from "axios";

function isSameDay(a, b) {
  return differenceInCalendarDays(a, b) === 0;
}

const DashboardAvailability = ({ vendor }) => {   
    const [value, onChange] = useState(new Date());
    const [disabledDates, setDisabledDates ] = useState([])
    const [ loaded, setLoaded ] = useState(false)
      
    useEffect(() => {
        axios.get('/api/vendor-unavailable/' + vendor.id)
        .then(r => {
            setDisabledDates(r.data.map((d) => {
                var date = new Date(d.date)
                date.setHours(date.getHours() + 5)
                return date
            }))
        })
    }, [])
    
    function tileClassName({ date, view }) {
        // Disable tiles in month view only
        if (view === 'month') {
            // Check if a date React-Calendar wants to check is on the list of disabled dates
            if(disabledDates.find(dDate => isSameDay(dDate, date))){
                return 'myClassName'
            }
        }
    }
   useEffect(() => {
       if (loaded) {
        const offset = value.getTimezoneOffset()
        var adjusted = new Date(value.getTime() - offset * 60 * 1000)
        var disab = [...disabledDates]
        var found = false
        for(var i in disab){
            if(isSameDay(disab[i], value)){
                disab.splice(i, 1)
                found = true
            }
        }
        if(!found){
            disab.push(value)
            axios.post('/api/vendor-unavailable', { date: adjusted.toUTCString(), vendorId: vendor.id})
        } else{
            axios.post('/api/vendor-available', { date: adjusted.toUTCString(), vendorId: vendor.id})           
        }
        setDisabledDates(disab)
    } else {
        setLoaded(true)
    }
   }, [value])

    return(
		<div className="vendor-dashboard-card">
            <div className='vendor-dashboard-card-header'>Mark Your Unavailable Dates</div>
           <Calendar 
            value={value}
            onChange={onChange}
            tileClassName={tileClassName}
            calendarType={"US"}
            showNeighboringMonth={false}
           />
        </div>
    )
}

export default DashboardAvailability;